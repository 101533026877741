//!* vars
	$shadow-1: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.1);
	$shadow-2: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);

	$background-body: #f0f0f0;
	$color-body: #424242;
	$color-border: #d0d0d0;
	$color-border-alt: #f0f0f0;
	$color-opacity: #9e9e9e;

	$spacing: 20px;
	$radius: 3px;
	$fsize: 15px;

	@mixin transform($transform) {
		-webkit-transform: $transform;
		-moz-transform: $transform;
		transform: $transform;
	}
	@mixin transition($transition) {
		-webkit-transition: $transition;
		-moz-transition: $transition;
		transition: $transition;
	}

//!* Ricardo Personalité
	$redrum-1: #ffffff; //3
	$redrum-2: #57953f; //2
	$redrum-3: #418927; //1
	
	// 1
	#login:before,
	.heading,
	.btn-default-2 { background:$redrum-3; color: white !important; }
	.btn-default-2-transparent { color: $redrum-3 !important; }
	.checkbox input:checked + span:before,
	.tabs-group > li:hover > a:before,
	.tabs-group > li.active > a:before { border-color: $redrum-3 !important; }
	.radio input:checked + span:before { border-color: $redrum-3; }
	.slider input:checked + span:before,
	.slider input:checked + span:after { background: $redrum-3; }
	.checkbox input + span:after,
	.radio input + span:after { color: white; }
	.badge.new,
	.tabs-group > li > a:before { background: $redrum-3; color: white !important; }
	.link-default.active,
	.link-default:hover,
	.sublink-default:hover,
	.link-default.link-nav:not(.collapsed),
	.sublink-active,
	.tabs-group > li.active > a,
	.tabs-group > li > a:hover { color: $redrum-3 !important; }

	// 3
	.btn-default-1 { background: $redrum-2 !important; color: white !important }
	.btn-default-1-transparent { color: $redrum-2 !important; }
	
	// 2
	.btn-default-3 { background: $redrum-1 !important; color: $color-body !important; }
	.btn-default-3-transparent { color: $redrum-1 !important; }
	
	// diferenciais
	.form-file .btn, .table-default .btn { background: white !important; color: $color-body !important; }
	.btns .btn-lg { background: white !important; color: $redrum-3 !important; }
	.btns .btn-md { color: $redrum-3 !important; }

	// error / success / delete
	$color-success: #57953f;
	$color-success-alt: #ffffff;
	$color-error: #e53935;
	$color-error-alt: #ffffff;
	$color-info: #1e88e5;
	$color-info-alt: #ffffff;

	.btn-delete { background: $color-error; color: $color-error-alt !important; }
	.has-error .form-control { border-bottom-color: $color-error !important; }
	.has-error > .label { color: $color-error !important; }

//!* resets default
	body {
		background: $background-body;
		color: $color-body;
		font-size: $fsize;
		-webkit-font-smoothing: antialiased;
	}

	a {
		text-decoration: none !important;
		color: $color-body;
		@include transition(all .15s linear);
		&.link {
			color: $color-body !important;
			text-decoration: underline !important;
			&:hover {
				opacity: 0.75;
			}
		}
	}

	p {
		margin: 0 0 $spacing;
		line-height: 1.5em;
		&:last-child { margin: 0; }
	}

	.lead { font-weight: 400; }

	hr {
		border-top: 1px solid $color-border;
		margin: ($spacing * 2) 0;
		@media (max-width : 767px) { margin: $spacing 0; }
	}

	form { margin: 0;}

	.heading-block { margin-bottom: $spacing; }

	.font-light { font-weight: 300; }
	.font-regular { font-weight: 400; }
	.font-medium { font-weight: 500; }
	.font-bold { font-weight: 700; }
	.margin-top-lg { margin-top: $spacing; }
	.margin-bottom-lg { margin-bottom: $spacing; }
	.margin-0.form-group { margin: 0; }
	@media (max-width : 1199px) {
		.text-center-md { text-align: center; }
		.text-left-md { text-align: left; }
		.text-right-md { text-align: right; }
		.margin-top-md { margin-top: $spacing; }
		.margin-bottom-md { margin-bottom: $spacing; }
	}
	@media(max-width: 1259px) {
		.margin-0.form-group + .margin-0.form-group { margin-top: $spacing !important; }
	}
	@media (max-width : 991px) {
		.text-center-sm { text-align: center; }
		.text-left-sm { text-align: left; }
		.text-right-sm { text-align: right; }
		.margin-top-sm { margin-top: $spacing; }
		.margin-bottom-sm { margin-bottom: $spacing; }
	}	
	@media (max-width : 767px) {
		.text-center-xs { text-align: center; }
		.text-left-xs { text-align: left; }
		.text-right-xs { text-align: right; }
		.margin-top-xs { margin-top: $spacing; }
		.margin-bottom-xs { margin-bottom: $spacing; }
	}

	h1 {
		margin: ($spacing * 2) 0 $spacing;
		font-size: 42px;
		font-weight: 400;
		small {
			font-size: 24px;
		}
		@media (max-width : 767px) {
			margin: $spacing 0;
			font-size: 30px;
			small {
				font-size: 18px;
			}
		}
	}

	h2 {
		font-size: 30px;
		font-weight: 400;
		margin: ($spacing * 2) 0;
		@media (max-width : 767px) {
			font-size: 20px;
			margin: $spacing 0;
			small {
				font-size: 17px;
			}
		}
	}

	h3 {
		font-size: 24px;
		font-weight: 400;
		margin: $spacing 0;
		@media (max-width : 767px) {
			font-size: 15px;
			small {
				font-size: 13px;
			}
		}
	}

	h1, h2, h3 {
		.material-icons { font-size: .95em !important; }
		&:first-child { margin-top: 0; }
		.btn .material-icons {
			font-size:  24px !important;
		}
	}

	.prp {
		position: relative;
		overflow: hidden;
		border-radius: $radius;
		display: block;
		box-shadow: $shadow-1;
		background: white;
		&.square { padding-bottom: 100%; }
		&.video { padding-bottom: 56.3%; }
		> * {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
		}
	}

	.fancybox-is-open .fancybox-bg {
		background: $color-body !important;
	}

//!* resets
	.inline { display:inline-block; }

	.material-icons { vertical-align: middle; }

	.badge {
		font-size: 13px;
		line-height:14px;
		vertical-align: middle;
		background: none;
		border: 1px solid $color-border;
		color: $color-opacity;
		font-weight: 500;
		border-radius: $radius;
		padding: 5px 7px !important;
		&.circle { border-radius: 50px; }
		&.new {
			color: white;
			border: none;
		}
	}

	.panel {
		box-shadow: none;
		border: none;
		background: none;
		margin: 0 !important;
		border-radius: 0 !important;
	}

//!* contents
	header {
		z-index: 99 !important;
		nav {
			position: fixed;
			top: 0;
			left: 0;
			background: white;
			box-shadow: $shadow-1;
			width: 275px;
			height: 100%;
			overflow-y: auto;
			overflow-x: hidden;
			-webkit-overflow-scrolling: touch;
			z-index: 99;
			@include transition(all .15s linear);
			.panel + .panel { margin-top: 5px !important; }
			//.link-default span { width: 200px; }
			.divisor.panel {
				margin: 10px 0 !important; 
				height: 1px;
				background: $color-border-alt !important;
				&:first-child { margin-top: 0 !important; }
			}
		}
		.navbar-fixed-top {
			background: white;
			box-shadow: $shadow-1;
			border: none;
			padding: 18px ($spacing) 18px 60px;
			z-index: 97;
			.title {
				display: block;
			    font-weight: 500;
			    font-size: 24px;
			    line-height: 24px;
			    overflow: hidden;
			    text-overflow: ellipsis;
			    white-space: nowrap;
			    @media (max-width : 767px) {
			    	font-size: 20px;
			    }
			}
			button {
				position: absolute;
				top: 0;
				left: 0;
				width: 60px;
				height: 60px;
				outline: none !important;
				background: none !important;
				border: none;
			    .material-icons {
			    	font-size: 30px !important;
				    @media (max-width : 767px) {
				 		font-size: 24px !important;   	
				    }
			    }
			}
		}
		.navbar-dismiss {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #000000;
			opacity: 0.5;
			z-index: 98;
			padding: 0;
			border: none;
			opacity: 0;
			visibility: hidden;
			@include transition(all .3s linear);
		}

		@media (max-width: 991px) {
			nav { @include transform(translateX(-275px)); }

			&.open {
				nav { @include transform(translateX(0px)); }
				.navbar-dismiss {
					opacity: 0.65;
					visibility: visible;
				}
			}
		}

	}	

	footer {
		padding: ($spacing * 2);
		padding-left: (($spacing * 2) + 275px);
		@media (max-width : 991px) { padding: ($spacing * 2); }
		@media (max-width : 767px) { padding: $spacing; }
		a {
			display: block;
			margin: 0 auto;
			width: 25px;
			opacity: 0.5;
			&:hover { opacity: 1; }
		}
	}

	#login + footer {
		padding: ($spacing / 2) $spacing $spacing !important;
	}
	
	.right-container {
		padding-left: 275px;
		@include transition(all .15s linear);
		@media (max-width : 991px) {
			padding: 60px 0 0;
		}
		.content, .heading {
			padding: $spacing * 2;
			@media (max-width : 767px) {
				padding: ($spacing + ($spacing / 2)) $spacing;
			}
		}
		.list {
			padding-left: 0;
			padding-right: 0;
			> .alert, .list-subitem {
				margin: ($spacing / 2) ($spacing * 2) $spacing;
				@media (max-width : 767px) {
					margin: 0 $spacing $spacing;
				}
				&:last-child { margin-bottom: 0; }
			}
		}
		.heading {
			position: relative;
			padding: 33px ($spacing * 2);
			@media (max-width : 767px) {
				padding: ($spacing + ($spacing / 2)) $spacing
			}
			h1 {
				font-weight: 300;
				margin: 0;
				small {
					display: none;
					margin-bottom: $spacing / 3;
					color: inherit;
					opacity: .6;
					font-weight: 300;
					&.bottom { margin: ($spacing / 3) 0 0; }
					@media (max-width : 767px) { margin: 0 !important; }
				}
			}
			.btns  {
				position: absolute;
				bottom: -30px;
				right: $spacing * 2;
				.btn + .btn { margin-left: 6px; }
				@media (max-width : 767px) {
					bottom: -22px;
					right: $spacing;
					.btn-icon { padding: 10px !important; }
				}
			}
		}

		.btn-actions {
			text-align: center;
			margin-top: $spacing * 2;
		}
	}

	.row-table {
		display: table;
		width: 100%;
		margin-bottom: $spacing;
		&:last-child {
			margin-bottom: 0;
		}
		> div {
			display: table-cell;
			vertical-align: middle;
			&.col-primary { width: 100%; }
			&.col-half { width: 50%; }
		}
		> div + div { padding-left: $spacing; }
		@media (max-width : 1199px) { &.row-break-md > div { display: block !important; padding-left: 0!important; width: 100% !important; } }
		@media (max-width : 991px) { &.row-break-sm > div { display: block !important; padding-left: 0!important; width: 100% !important; } }
		@media (max-width : 767px) { &.row-break-xs > div { display: block !important; padding-left: 0!important; width: 100% !important; } }
	}

	.table-responsive { border: none !important; margin:0 !important; }

	.table { th, td { border-color: $color-border !important; }	}
	.table-border { th + th, td + td { border-left: 1px solid; } }
	.table-attributes {
		margin: 0 !important;
		.var, .att { vertical-align: middle; }
		.var {
			min-width: 250px;
			text-align: right;
			font-size: 90%;
			word-break: break-all;
			span {
				display: block;
				line-height: 1.15em;
			}
		}
		.att {
			padding: 0 !important;
			width: 100%;
			.form-control {
				border: none !important;
				padding-right: 15px !important;
				padding-left: 15px !important;
			}
			textarea.form-control {
				min-height: 80px !important;
			}
		}
	}

	.table-default {
		margin-bottom: ($spacing * 2) !important;
		.drop-menu { min-width: 101%; }

		th:first-child, td:first-child {
			padding-left: $spacing * 2;
			@media (max-width : 767px) {
				padding-left: $spacing;
			}
		}
		th:last-child, td:last-child {
			padding-right: $spacing * 2;
			@media (max-width : 767px) {
				padding-right: $spacing;
			}
		}
		th, td { vertical-align: middle !important; }
		th {
			border-bottom-color: $color-border !important;
			font-weight: 500;
		}
		td {
			border-top: none;
			border-bottom: 1px solid $color-border !important;
		}
		.actions {
			width: 60px;
			text-align: center;
			white-space: nowrap;
			&.text-left { text-align: left !important; }
		}
		tbody tr:hover td { background: #e8e8e8; }
		tfoot td {
			border-top: 2px solid $color-border !important;
			border-bottom: none !important;
		}
		.checkbox, .radio {
			padding: 0 !important;
			width: 18px; 
			height: 18px;
		}
		.slider {
			padding: 0 !important;
			width: 38px;
			height: 20px;
		}
	}

	.group-collapse, .group-links {
		border-radius: $radius;
		box-shadow: $shadow-1;
		background: white;
		margin-bottom: $spacing;
		> .panel + .panel, > li + li { border-top: 1px solid $color-border !important; }
		.link-nav {
			.material-icons {
				@include transform(rotate(0deg));
				@include transition(all .15s linear);
			}
			&:not(.collapsed) .material-icons { @include transform(rotate(45deg)); }
		}
		&:last-child { margin-bottom: 0; }
	}

	.sortable {
		&.sortable-text {
			position: relative;
			> li {
				&:first-child { border-radius: $radius $radius 0 0; }
				&:last-child { border-radius: 0 0 $radius $radius; }
				&.placeholder {
					height: 45px;
					background: $color-border;
					&:first-child { border-radius: $radius $radius 0 0; }
					&:last-child { border-radius: 0 0 $radius $radius; }
				}
				&:active { position:absolute; width:100%; border-color: white !important; }
				a.link-default {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					cursor: move !important;
					color: $color-body !important;
					&:active {
						z-index: 999;
						box-shadow: $shadow-1 !important;
						background: white;
						border-color: white !important;
					}
				}
			}
		}

		&.sortable-photo {
			margin: 5px 0 0;
			&:before, &:after {
				content: '';
				clear: both;
				display: block;
			}
			> li {
				position: relative;
				float: left;
				margin: 0 ($spacing / 2) ($spacing / 2) 0;
				background: $color-border;
				width: 120px;
				height: 120px;
				border-radius: $radius;
				cursor: move;
				img {
					display: block;
					width: 100%;
					height: 100%;
					border-radius: $radius;
				}
				.dropdown {
					position: absolute;
					right: 5px;
					bottom: 5px;
					.drop-menu { min-width: inherit; }
				}
				&.placeholder {
					background: none !important;
					border: 2px dashed $color-border !important;
				}
				&:active {
					z-index: 1;
					box-shadow: $shadow-1 !important;
				}
			}
		}
	}

	.tabs-group {
		margin-bottom: $spacing / 2;
		width: 100%;
		white-space: nowrap;
		-webkit-overflow-scrolling: touch;
		overflow: auto;
		li {
			display: inline-block;
			vertical-align: bottom;
			a {
				display: block;
				padding: 7px 16px 10px;
				font-weight: 500;
				text-transform: uppercase;
				color: $color-body;
				position: relative;
				&:before {
					content: '';
					display: block;
					width: 0;
					height: 3px;
					position: absolute;
					bottom: 0;
					left: 50%;
					@include transition(all .15s linear);
				}
			}
			&.active a:before { 
				left: 0;
				width: 100%;
			}
		}
		&.tabs-lang {
			li {
				a {
					font-weight: 600;
					width: 50px;
					height: 50px;
					line-height: 1em;
					padding: 19px 0;
					color: $color-opacity;
					text-align: center;
					&:before {
						left: 0;
						width: 100%;
						height: 100%;
						background: none !important;
						border-radius: 50%;
						border: 1px solid $color-border;
					}
				}				
				&.active a:before { border-width: 2px; }
			}
		}
	}

	.tab-content {
		margin-bottom: $spacing;
		&:last-child { margin-bottom: 0; }
	}
	.padding-box { padding: $spacing; }
	.white { background: white; }
	.gray { background: $background-body; }
	.shadow { box-shadow: $shadow-1; }
	.radius { border-radius: $radius; }
	.text-danger, .link-default.text-danger { color: $color-error !important; }
	.text-success, .link-default.text-success { color: $color-success !important; }

//!* forms
	.label {
		display: block;
		margin: 0;
		padding: 0;
		font-weight: normal;
		font-size: 1em;
		color: $color-opacity;
		text-align: left;
		background: $background-body;
		@include transition(all .15s linear);
	}

	.form-group {
		position: relative;
		margin-bottom: ($spacing + 5px);
		&:before {
			content: '';
			display: block;
			height: 10px;
		}
		&.no-label:before { display:none; }
		&.option-group {
			border-bottom: 2px solid $color-border;
			&:before { height: 16px; }
		}
		.badge {
			margin: 10px 0 13px;
		}
		.form-counter-label {
			margin: -9px 0 0 !important;
			position: absolute;
			top: 50%;
			right: 0;
		}
		&[class*="col-"] > .form-counter-label { right: 15px; }
		.form-counter { padding-right: 160px !important; }
		&:last-child { margin-bottom: 0; }

		&:not(.form-file) .label {
			font-size: 15px;
			font-weight: 500;
			position: absolute;
			left: 0;
			top: 0;	
		}
		&:not(.form-file)[class*="col-"] > .label {
			left: 15px;
		}

		&:not(.form-file) .form-control:focus + .label { color: $color-body; }
	}

	.form-control {
		border-radius: 0;
		border: none;
		border-bottom: 2px solid $color-border;
		box-shadow: none !important;
		background: none !important;
		color: $color-body;
		padding-left: 0;
		padding-right: 0;
		font-size: $fsize;
		&:focus {
			border-bottom-color: $color-opacity;
		}
		&:disabled { opacity: 0.5; }
	}

	textarea.form-control {
		min-height: 100px;
		resize: vertical;
	}

	.select-box {
		&:after {
			content: 'keyboard_arrow_down';
			font-family: 'Material Icons';
			font-size: 24px;
			line-height: 1em;
			width: 1em;
			height: 1em;
			color: $color-opacity;
			position: absolute;
			top: 50%;
			right: 0;
			margin-top: -7px;
			pointer-events: none;
		}
		&[class*="col-"]:after { right: 15px; }
		.form-control {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			line-height: 1.6;
			padding-right: 29px !important;
			&:invalid { color: $color-opacity !important; }
		}		
	}

	.checkbox, .radio {
		position: relative;
		margin: 0 !important;
		padding: 7px 20px 8px 28px;
		cursor: pointer;
		font-weight: 500;
		z-index: 1;
		span {
			display: block;
			color: $color-opacity;
			@include transition(all .15s linear);
			&:before, &:after {
				display: block;
				position: absolute;
				top: 50%;
				left: 0;
				width: 18px;
				height: 18px;
				line-height: 14px;
				margin-top: -9px;
			}
			&:before {
				margin-top: -9px;
				left: 0;
				content: '';
				border-radius: $radius;
				border: 2px solid $color-opacity;
				z-index: 1;
				@include transition(border-color .1s linear);
			}
			&:after {
				padding: 2px;
				font-size: 14px;
				font-family: 'Material Icons';
				z-index: 2;
				@include transform(scale(0.5));
				@include transition(all .1s linear);
				opacity: 0;
			}
		}
		input:checked + span { color: $color-body; }
		input:checked + span:before {
			border-width: 9px;
		}
		input:checked + span:after {
			@include transform(scale(1));
			opacity: 1;
		}
	}

	.radio {
		input + span:before { @include transition(all .1s linear); }
		input:checked + span:before { border-width: 5px !important; }

	}

	.slider {
		display: block;
		position: relative;
		z-index: 1;
		font-weight: 500;
		padding: 10px 35px 10px 45px; 
		margin: 0;
		cursor: pointer;
		&.slider-inline {
			display: inline-block;
		}
		span {
			display: block;
			color: $color-opacity;
			@include transition(all .15s linear);
			&:before, &:after {
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				left: 0;
				opacity: 1;
				@include transform(translateX(0px));
				@include transition(all .1s linear);
			}
			&:before {
				z-index: 1;
				width: 37px;
				height: 12px;
				border-radius: 9px;
				margin-top: -6px;
				background: $color-opacity;
			}
			&:after {
				position: absolute;
				width: 18px;
				height: 18px;
				margin-top: -9px;
				border-radius: 9px;
				background: $color-border;
				//box-shadow: 0 1px 3px 1px rgba(0,0,0,0.4);
				z-index: 2;
			}
		}
	}

	.slider input:checked + span:before { opacity: 0.45; }
	.slider input:checked + span:after { @include transform(translateX(20px)); }
	.slider input:checked + span { color: $color-body; }
	.radio span:before { border-radius: 50%; }
	.radio span:after { display: none !important; }
	.checkbox span:after { content:'check'; }
	.radio span:after { content:'fiber_manual_record'; font-size: 14px; }

	.form-file {
		display: block;
		font-weight: 400;
		position: relative;
		min-height: 58px;
		padding-left: (58px + ($spacing / 2));
		width: 100%;
		.left {
			position: absolute;
			left: 0;
			top: 50%;
			margin-top: -29px;

		}
		.btn {
			width: 58px;
			height: 58px;
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				display: block;
				@include transform(scale(1.02));
			}
		}
		.right {
			display: block;
			overflow: hidden;
			padding: 0 0 5px;
			border-bottom: 2px solid $color-border;
			.info {
				display: block;
				color: $color-opacity;
			}
			b {
				display: block;
				margin-top: 3px;
				font-weight: 500;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.label {
				font-size: 15px;
				margin-bottom: 2px;
				font-weight: 500;
			}
		}
	}

	.checkbox, .radio, .slider, .form-file {
		input {
			position: absolute;
			left: -9999em;
			top: 0;
			opacity: 0;
			visibility: hidden;
			z-index: -1;
		}
		input:disabled + span {
			cursor: not-allowed !important;
			opacity: 0.35;
			-webkit-filter: grayscale(1);
			-moz-filter: grayscale(1);
		}
		&:has(input:disabled) { cursor: not-allowed; }
	}

	::-webkit-input-placeholder { color: $color-opacity !important; }
	::-moz-placeholder { color: $color-opacity !important; }
	:-ms-input-placeholder { color: $color-opacity !important; }
	:-moz-placeholder { color: $color-opacity !important; }
	
	input:-webkit-autofill,
	input:-webkit-autofill:hover, 
	input:-webkit-autofill:focus
	input:-webkit-autofill, 
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		-webkit-text-fill-color: $color-body;
		-webkit-box-shadow: 0 0 0px 1000px $background-body inset;
		transition: background-color 5000s ease-in-out 0s, border .15s linear, color .15s linear;
	}

	#login :-webkit-autofill {
		-webkit-text-fill-color: $color-body;
		box-shadow: 0 0 0px 1000px white inset !important;
	}

//!* elements
	.btn {
		position: relative;
		z-index: 1;
		box-shadow: $shadow-1 !important;
		padding: 7px 16px;
		border: none;
		font-size: $fsize;
		font-weight: bold;
		text-transform:uppercase;
		border-radius: $radius;
		outline: none !important;
		@include transform(scale(1));
		@include transition(all .1s linear);
		&.btn-lg { padding: 13px 27px; }
		&.btn-xl { padding: 17px 37px; }
		&:hover { box-shadow: $shadow-2 !important; }
		&:active { @include transform(scale(0.95)); }
		&[class$="-transparent"] { box-shadow: none !important; background: none !important; }
		.material-icons { line-height: 18px; }
		> * {
			display: inline-block;
			vertical-align: middle;
		}
		> * + * { margin-left: ($spacing / 3); }
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
			background: rgba(255,255,255,0.09);
			border-radius: $radius;
			opacity: 0;
			visibility: hidden;
			@include transition(all .15s linear);
		}
		&[class$="-transparent"]:before { background: rgba(0,0,0,0.09) !important; }
		&:hover:before, &:active:before {
			opacity: 1;
			visibility: visible;
		}
		&:active:before { background: rgba(255,255,255,0.23); }
		&[class$="-transparent"]:active:before { background: rgba(0,0,0,0.23); }
		&.disabled, &:disabled {
			opacity: 0.35 !important;
			box-shadow: none !important; 
			@include transform(scale(1));
			-webkit-filter: grayscale(1);
			-moz-filter: grayscale(1);
			cursor: not-allowed !important;
		}
	}
	.btn-icon {
		padding: 10px;
		border-radius: 50%;
		line-height:1em;
		> * { margin: 0 !important; }
		.material-icons {
			display: block;
			line-height: 1em !important;
		}
		&:before { border-radius: 50%; }
		&.btn-xs {
			padding: 7px;
			.material-icons { font-size: 18px !important; }
		}
		&.btn-lg { padding: 17px; }
	}

	.link-default {
		position: relative;
		display: block;
		padding: ($spacing / 2) $spacing;
		color: $color-body !important;
		span {
			display: inline-block;
			vertical-align: middle;
			font-weight: 500;
			padding-left: 35px;
		}
		.material-icons {
			position: absolute;
			top: 50%;
			margin-top: -.5em;
			left: $spacing;
			vertical-align: middle;
		}
		.badge {
			position: absolute;
			right: $spacing;
			top: 50%;
			margin-top: -12px;
		}
		&.no-icon span { padding-left: 0; }
	}

	button.link-default, label.link-default {
		width: 100%;
		border: none;
		background: none;
		text-align: left;
		margin: 0;
	}

	span.link-default:hover {
		color: $color-body !important;
		cursor: default !important;
	}

	.sublink-default {
		display: block;
		padding: ($spacing / 2) $spacing ($spacing / 2) ($spacing + 28px + ($spacing / 3));
		color: $color-body !important;
		&.sublink-active {
			font-weight: 500;
			background: rgba(0,0,0,0.04);
		}
	}

	.alert {
		position: relative;
		font-weight: 500;
		border: none;
		border-radius: $radius;
		padding: $spacing * 2;
		color: white;
		&.alert-success { background: $color-success; }
		&.alert-danger { background: $color-error; }
		&.alert-info { background: $color-info; }
		&.alert-empty {
			background: none;
			color: $color-opacity;
			border: 2px dashed $color-border;
		}
		&.fade {
			@include transition(all .15s linear);
			@include transform(scale(0));
			&.in { @include transform(scale(1)) }
		}
		ul { padding-left: 16px; }
		.btn-close {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: none;
			border: none;
			box-shadow: none;
			opacity: 0;
		}

		&.message-order {
			padding: ($spacing / 2) $spacing;
		}
		@media (max-width : 767px) { padding: $spacing; }
	}

	.brand-image {
		display: block;
		padding: 33px $spacing; 
		img {
			max-width: 80%;
			max-height: 70px;
			/*@media (max-width : 767px) {
				max-width: 75%;
				max-height: 110px;
			}*/
		}
	}

	.drop-menu {
		position: absolute;
		text-align: left;
		margin: 0;
		top: -2px;
		left: -2px;
		z-index: 95;
		border:none;
		box-shadow: $shadow-1 !important;
		border-radius: $radius;
		padding: 0;
		list-style: none;
		background: white;
		min-width: 200px;
		max-width: 290px;
		opacity: 0;
		visibility: hidden;
		@include transform(scale(0.95));
		@include transition(all .1s linear);
		li + li { border-top: 1px solid $color-border; }
		a { outline: none !important; }
		&.right {
			left: auto;
			right: -2px;
		}
	}
	.dropup .drop-menu {
		top: auto;
		bottom: -2px;
	}
	.open > .drop-menu {
		@include transform(scale(1));
		visibility: visible;
		opacity: 1;
	}

	.modal {
		.modal-header {
			padding: ($spacing * 2) ($spacing * 2) $spacing;
			border: none;
		}
		.modal-body { padding: ($spacing * 2); }
		.modal-header + .modal-body { padding-top: 0 !important; }
		.modal-footer { padding: $spacing; }
		&.fade {
			.modal-dialog { @include transform(scale(0.9)); }
			&.in .modal-dialog { @include transform(scale(1)) }
		}
		@media (min-width : 768px) {
			.modal-dialog.modal-sm { width: 420px; }
		} 
		@media (max-width : 767px) {
			.modal-dialog { margin: $spacing; }
			.modal-header, .modal-body { padding: $spacing; }
		}

		&.modal-media {
			padding: $spacing !important;
			overflow: hidden !important;
			&:not(.fade) {
				animation: opacity_out .3s linear;
				&.in { animation: opacity_in .3s linear; }
			}
			.modal-dialog {
				position: relative;
				width: auto; 
				max-width: 1024px;
				height: 100%;
				margin: 0 auto;
				//pointer-events: none;
				@include transform(scale(1) !important)
			}
			&#modal-iframe .modal-dialog,
			&#modal-img .modal-dialog {
				width: 100%;
				height: 100%;
			}
			.dismiss {
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
				cursor: default;
			}
			video {
				position: relative;
				z-index: 2;
				max-width: 100%;
				width: 100%;
				height: 100%;
			}
			iframe {
				display: block;
				z-index: 2;
				max-height: 100%;
			    max-width: 100%;
			    position: absolute;
			    top: 0;
			    bottom: 0;
			    left: 0;
			    right: 0;
			    margin: auto;
			    border: none;
			    border-radius: $radius;
			    box-shadow: $shadow-2;
			}

			img {
				display: block;
				z-index: 2;
				max-height: 100%;
			    max-width: 100%;
			    width: auto;
			    height: auto;
			    position: absolute;
			    top: 0;
			    bottom: 0;
			    left: 0;
			    right: 0;
			    margin: auto;
			    border-radius: $radius;
			    box-shadow: $shadow-2;
			}
		}
	}

	.modal-backdrop {
		&.in { opacity: 0.65; }
		&:not(.fade) {
			animation: opacity_out_alt .3s linear;
			&.in { animation: opacity_in_alt .3s linear; }
		}
	}
	@keyframes opacity_in {
		from { opacity: 0; }
		to { opacity: 1; }
	}
	@keyframes opacity_out {
		from { opacity: 1; }
		to { opacity: 0; }
	}
	@keyframes opacity_in_alt {
		from { opacity: 0; }
		to { opacity: 0.65; }
	}
	@keyframes opacity_out_alt {
		from { opacity: 0.65; }
		to { opacity: 0; }
	}

	.tooltip {
		.tooltip-arrow { display: none; }
		.tooltip-inner {
			padding: 6px 10px;
			font-size: 13px;
			font-weight: 500;
			border-radius: $radius;
			background: rgba(0,0,0,0.8);
		}
		@media (max-width : 767px) {
			display: none !important;
		}
	}

	.tooltip-helper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 3;
		display: block;
	}

//!* login
	#login {
		position:relative;
		padding: ($spacing * 2) $spacing;
		width: 100%;
		&:before {
			content: '';
			position: absolute;
			bottom: 50%;
			left: 0;
			width: 100%;
			height: 1080px;
			z-index:1;
		}
		.login-form {
			background: white;
			box-shadow: $shadow-1;
			border-radius: $radius;
			padding: $spacing * 2;
			width: 320px;
			margin: 0 auto;
			position: relative;
			z-index: 2;
			@media (max-width : 767px) {
				width: 280px;
				padding: $spacing;
			} 
			@media (max-width : 479px) {
				width: auto;
			}
		}
		.brand-image {
			padding-left: 0;
			padding-right: 0;
			padding-top: 0;
			img {
				max-height: 100px;
				@media (max-height : 767px) {
					max-height: 70px;
				}
			}
		}
	}

//!* idiomas
	.form-file {
		.icon {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			background: white;
			img {
				max-height: 90%;
				max-width: 90%;
				width: auto;
				height: auto;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
				border-radius: 3px;
			}
		}
	}

//!* logs
	#table-log {
		th { outline: none !important; }
		td {
			vertical-align: top !important;
			&.text.inf {
				vertical-align: middle !important;
				line-height: 1.15em !important;
			}
		}
		.stack {
			font-size: .75em;
			padding-top: $spacing;
		}
	}
	#table-log_wrapper {
		> .row {
			margin: 0;
			> div { padding: 0; }
			&:first-child,
			&:last-child {
				margin: 0 ($spacing * 2) $spacing;
				@media (max-width : 767px) {
					margin: 0 $spacing $spacing;
				}
			}
		}
	}
	#table-log_paginate {
		margin-top: -10px;
		text-align: center;
		@media (min-width : 768px) {
			text-align: right;
		}
	}
	#table-log_info {
		margin-top: -10px;
		padding: 10px 0;
		line-height: 1.15em;
		@media (max-width : 767px) {
			text-align: center;
			margin-bottom: $spacing;
		}	
	}
	#table-log_length {
		select {
			display: inline !important;
			width: auto !important;
		}
	}
	.pagination {
		margin: 0;
		a {
			background: none !important;
			border: none !important;
			color: $color-body !important;
		}
		.disabled { opacity: .5; }
		.active { font-weight: bold; }
	}

	.level-2 {
		td { background: #e8e8e8; }
		&:hover td { background: #e0e0e0 !important; }
		td:first-child { font-weight: 500; }
		.material-icons { margin: 0 $spacing 0 ($spacing / 2); }
	}

//!* blocks

	.container-special {
		position: relative;
		max-width: 900px;
		margin-left: auto !important;
		margin-right: auto !important;
	}

	.header-blocks {
		position: relative;
		margin: ($spacing * 2) 0 ($spacing * 3);
		text-align: center;
		@media (max-width : 767px) { margin: $spacing 0; }

		.header-blocks-isset {
			position: relative;
			display: inline-block;
			padding: ($spacing / 2) $spacing ($spacing / 2) ($spacing / 2);
			border: 2px solid $color-border;
			border-radius: 100px;
			> h3 { margin: 0 0 0 ($spacing - 5); }
			> .inline { vertical-align: middle; }
			> .dropdown { position: static; }
			&:before, &:after {
				content: '';
				display: block;
				border: 15px solid transparent;
				position: absolute;
				bottom: 100%;
				left: 50%;
				margin-left: -15px;
			} 
			&:before { border-bottom-color: $color-border; }
			&:after {
				margin-bottom: -3px;
				border-bottom-color: $background-body;
			}
			@media (max-width : 767px) {
				position: static;
				> h3 { font-size: 20px; }
			}
		}
		.drop-menu {
			overflow: hidden;
			max-width: none !important;
			padding: $spacing - 5;
			width: 798px;
			left: 50%;
			margin-left: -399px !important;
			padding: $spacing ($spacing - 5) ($spacing - 5);
			a {
				display: block;
				float: left;
				width: 33.333333%;
				opacity: 1;
				cursor: pointer;
				&:hover { opacity: .5; }
			}
			h4 {
				margin: 0 (-($spacing - 5)) ($spacing - 5);
				padding: 0 ($spacing - 5) ($spacing - 5);
				border-bottom: 1px solid $color-border;
			}

			@media (max-width : 1199px) {
				width: 640px;
				margin-left: -320px !important;
			}				
			@media (max-width : 767px) {
				padding-left: 0;
				padding-right: 0;
				left: 0;
				width: 100% !important;
				margin-left: 0 !important;
				h4 {
					font-size: 16px;
					&:after {
						content: '(deslize para o lado para ver todas opções)';
						font-size: 75%;
						padding: 0 7px;
						color: $color-opacity;
						font-weight: 400;
						display: inline-block;
					}
				}
				a {
					display: inline-block;
					vertical-align: top;
					float: none;
					width: 50%;
				}
				.blocks {
					overflow: auto;
					-webkit-overflow-scrolling: touch;
					white-space: nowrap;
				}
			}
		}
	}

	.block-group {
		.block:first-child .btn-handle-up,
		.block:last-child .btn-handle-down {
			opacity: 1 !important;
			box-shadow: none !important; 
			background: #e0e0e0 !important;
			color: #909090 !important;
			@include transform(scale(1));
			cursor: not-allowed !important;
			.tooltip-helper { display: none !important; }
		}

		.block {
			position: relative;
			padding: ($spacing * 2);
			margin-bottom: ($spacing * 2);
			margin-right: ($spacing + 44px);
			background: white;
			min-height: 150px;
			border-radius: $radius;
			box-shadow: $shadow-1;
			.actions {
				position: absolute;
				top: 50%;
				left: 100%;
				margin-top: -78px;
				padding-left: $spacing;
				.btn-default-3 { margin-top: 5px; }
				.dropdown { margin-bottom: 15px; }
			}
			.label { background: white !important; }
			textarea {
				max-height: 400px !important;
				min-height: 239px !important;
			}
			select { font-size: 1em !important; }
			.row {
				@media (max-width : 1259px) {
					> div:not(.brand-col) { width: 100%; }
					> div + div { padding-top: $spacing * 2 !important; }
				}
			}

			textarea.form-control {
				font-size: 20px;
				@media (max-width : 991px) { font-size: 19px; }
			    @media (max-width : 767px) { font-size: 18px; }
			    @media (max-width : 479px) { font-size: 17px; }
			}

			.form-file {
				&.file4by3 {
					margin-top: 75% !important;
					.image-container { padding-top: 75% !important; }
				}
				margin-top: 46.8%;
				padding-top: $spacing;
				margin-bottom: 0 !important;
				&:before { display: none !important; }
				.label { background: none !important; }
				.image-container {
					display: block;
					cursor: pointer;
					margin: 0;
					position: absolute;
					bottom: 100%;
					left: 0;
					width: 100%;
					padding-top: 46.8%;
					text-align: center;
					font-weight: 400;
					color: $color-opacity;
					overflow: hidden;
					&.border { border: 2px dashed $color-border; }
					img {
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
					}
					span {
						position: absolute;
						left: 0;
						top: 50%;
						margin-top: -12px;
						font-size: 24px;
						line-height: 24px;
						width: 100%;
						.material-icons {
							font-size: 32px;
							vertical-align: sub;
						}
						@media (max-width : 767px) {
							font-size: 15px;
							.material-icons { font-size: 24px; }
						}
					}
				}
				.left {
					top: $spacing;
					margin: 0 !important;
				}
			}

			.single-item { margin-top: 15px; }

			.prp.video {
				box-shadow: none !important;
				border-radius: 0;
				margin-bottom: $spacing;
				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border: 2px dashed $color-border;
					z-index: 1;
				}
				span {
					position: absolute;
					left: 0;
					top: 50%;
					margin-top: -12px;
					font-size: 24px;
					line-height: 24px;
					width: 100%;
					color: $color-opacity;
					text-align: center;
					z-index: 1;
					.material-icons {
						font-size: 32px;
						vertical-align: sub;
					}
					@media (max-width : 767px) {
						font-size: 15px;
						.material-icons { font-size: 24px; }
					}
				}
				iframe {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 2;
				}
			}
			
			@media (max-width : 767px) {
				padding: $spacing;
				padding-bottom: ($spacing + 22);
				margin-bottom: ($spacing + 22);
				margin-right: 0;
				.actions {
					top: auto;
					left: 0;
					margin: 0;
					text-align: center;
					bottom: -22px;
					width: 100%;
					padding: 0;
					.btn-default-3 { margin: 0 0 0 5px; }
					.dropdown {
						display: inline-block;
						margin: 0 15px 0 0;
						.drop-menu {
							top: auto !important;
							right: auto !important;
							left: 0 !important;
							bottom: 0 !important;
						}
					}
				}
			}
		}
	}

	.historia {
		.block .form-file { margin-bottom: $spacing !important; }
		.block {
			margin-right: 0 !important;
			margin-bottom: $spacing + 32px;
			.actions {
				top: auto;
				left: 0;
				margin: 0;
				text-align: center;
				bottom: -22px;
				width: 100%;
				padding: 0;
				.btn-default-3 { margin: 0 0 0 5px !important; }
				.dropdown {
					display: inline-block;
					margin: 0 0 0 15px;
					.drop-menu {
						top: auto !important;
						right: auto !important;
						left: 0 !important;
						bottom: 0 !important;
					}
				}
			}
		}
	}

	.programas .block .form-control {
		font-size: 20px;
		@media (max-width : 991px) { font-size: 19px; }
	    @media (max-width : 767px) { font-size: 18px; }
	    @media (max-width : 479px) { font-size: 17px; }
	}

	#privacy {
		position: fixed;
		left: -100vw;
	}

	.editable {
		height: auto;
		min-height: 16rem;
		padding: 4rem;
		margin-top: 1.25rem;
		border-radius: $radius !important;
		border: 1px solid $color-border !important;
		border-bottom-width: 3px !important;
		background: $redrum-1 !important;

		> * {
			margin: 0 !important;

			& + * {
				margin-top: 16px !important;
			}
		}

		h1,h2,h3 {
			color: $redrum-3;
			font-weight: bold;
		}

		h1 {
			font-size: 30px;
		}

		h2 {
			font-size: 25px;
		}

		h3 {
			font-size: 20px;
		}
	}