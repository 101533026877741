/*!
 * Sou Digital (http://sou.digital/)
 * Webdesign: Ricardo A. Kowalski (http://behance.net/ricardo-a-k)
 * Março de 2017.
 * Life in plastic is fantastic.
 * Redrum. Redrum. Redrum.
!*/

// Fonts
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// jquery
@import '~jquery-ui/themes/base/all.css';

// editor
@import '~medium-editor/dist/css/medium-editor.css';
@import '~medium-editor/dist/css/themes/beagle.css';

//admin
@import "admin";
